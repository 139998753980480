<template>
  <main class="color-primary h-100 cancellation-bg blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('CANCELLATION.CANCELLATION') }}
    </p>
    <div class="cancellation-main-content">
      <div class="d-flex justify-content-between">
        <div class="px-2">
          <label>{{ $t('CANCELLATION.TRANSACTION_NUMBER') }}</label>
          <input type="text" class="input" v-model="transaction_number" />
        </div>
        <div class="px-2">
          <label>{{ $t('CANCELLATION.BUSINESS_NUMBER') }}</label>
          <input type="text" class="input" v-model="business_number" />
        </div>
      </div>
      <div class="px-2 mt-4">
        <button class="primary-btn">
          {{ $t('SUPPLIER_PAYMENT.CHECK') }}
        </button>
      </div>
    </div>
  </main>  
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Cancellation',
  data() {
    return {
      transaction_number: "",
      business_number: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.cancellation-main-content {
  max-width: 420px;
  margin: 10px auto;
}
</style>